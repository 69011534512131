import React, { useState, useEffect, useRef } from "react";
import { Link, Accordion, AccordionItem } from "@nextui-org/react";


export function Faq() {   
    return (
        <>
            <Accordion isCompact defaultExpandedKeys={["1"]}>
                <AccordionItem key="1" aria-label="Accordion 1" title="What platform is this token-gating service used for?">
                    <p className="text-default-500 text-sm">Telegram</p>
                </AccordionItem>
                <AccordionItem key="2" aria-label="Accordion 2" title="Can I use this service to token-gate my own Telegram group?">
                    <p className="text-default-500 text-sm">Soon. Reach out to <Link className="text-sm" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.me/mar2194">mar2194</Link> or <Link className="text-sm" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.me/babushka_slayer">babushka_slayer</Link> on Telegram.</p>
                </AccordionItem>
                <AccordionItem key="3" aria-label="Accordion 3" title="Wen Token?">
                    <p className="text-default-500 text-sm">👀</p>
                </AccordionItem>
                <AccordionItem key="4" aria-label="Accordion 4" title="How is OzKey compared to x, y, z?">
                    <p className="text-default-500 text-sm">OzKey is for the best in the world. There is no comparison.</p>
                </AccordionItem>

                {/* <AccordionItem key="3" aria-label="Accordion 3" title="What chains does OzKey support?">
                    <p>We currently support all EVM chains as well as Solana.</p>
                </AccordionItem>
                <AccordionItem key="3" aria-label="Accordion 3" title="What token standards can I use for token-gating?">
                    <p>ERC20, ERC1155, ...</p>
                </AccordionItem> */}
            </Accordion>
        </>
    );
}