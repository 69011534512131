import React, { useState, useEffect, useRef } from "react";

import {Link} from "@nextui-org/react";
import {Image, Button, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Tooltip} from "@nextui-org/react";

import { SiGitbook } from "react-icons/si";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


export function CustomNavbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <Navbar isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen}>
                <NavbarContent>
                    <NavbarMenuToggle
                        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                        className="sm:hidden px-4"
                    />

                    <NavbarBrand>
                        {/* <Image
                            alt="nextui logo"
                            height={48}
                            radius="lg"
                            src="./8bit_dog.png"
                            width={48}
                        /> */}
                        {/* <p className="font-bold text-inherit ml-1">TOTO</p> */}
                        <Link className="font-bold text-inherit" href="/">OZKEY</Link>
                    </NavbarBrand>
                </NavbarContent>

                {/* <NavbarContent className="hidden sm:flex gap-4" justify="center">
                    <NavbarItem>
                        <Link color="foreground" href="/pricing">Pricing</Link>
                    </NavbarItem>

                    <NavbarItem>
                        <Link color="foreground" href="/dashboard">Dashboard</Link>
                    </NavbarItem>
                </NavbarContent> */}

                <NavbarContent justify="end">
                    <div className="hidden sm:flex gap-1">
                        <Tooltip content="Twitter">
                            <NavbarItem>
                                    <Button color="default" variant="flat" size="sm" as={Link} href="https://twitter.com/ozkeybot" isExternal={true} isIconOnly aria-label="Twitter">
                                        <FaXTwitter />
                                    </Button> 
                            </NavbarItem>
                        </Tooltip>

                        {/* <Tooltip content="Telegram">
                            <NavbarItem>
                                    <Button color="default" variant="flat" size="sm" as={Link} href="https://t.co/KiaAdQq7hV" isExternal={true} isIconOnly aria-label="Telegram">
                                        <FaTelegramPlane />
                                    </Button> 
                            </NavbarItem>
                        </Tooltip> */}

                        <Tooltip content="Gitbook Coming Soon...">
                            <NavbarItem>
                                    <Button isDisabled color="default" variant="flat" size="sm" as={Link} href="https://api.oz.xyz" isExternal={true} isIconOnly aria-label="Gitbook">
                                        <SiGitbook />
                                    </Button> 
                            </NavbarItem>
                        </Tooltip>
                    </div>
                </NavbarContent>

                <NavbarMenu>
                    {/* <div className="mb-4">
                        <p>Services</p>

                        <NavbarMenuItem>
                            <Link className="text-default-500" href="/pricing" size="lg">Pricing</Link>
                        </NavbarMenuItem>

                        <NavbarMenuItem>
                            <Link className="text-default-500" href="/dashboard" size="lg">Dashboard</Link>
                        </NavbarMenuItem>
                    </div> */}

                    <div>
                        <p>Socials</p>

                        <NavbarMenuItem>
                            <Link className="text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://twitter.com/ozkeybot">Twitter</Link>
                        </NavbarMenuItem>

                        {/* <NavbarMenuItem>
                            <Link className="text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.co/KiaAdQq7hV">Telegram</Link>
                        </NavbarMenuItem> */}

                        <NavbarMenuItem>
                            <Link isDisabled className="text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://api.oz.xyz">Gitbook</Link>
                        </NavbarMenuItem>
                    </div>
                </NavbarMenu>
            </Navbar>
        </>
    );
}