import React, { useState, useEffect, useRef } from "react";

import {Card, CardHeader, CardBody, CardFooter, Image, Divider, Link, Button, Tooltip, select, Spinner, Chip} from "@nextui-org/react";

import { useAuth } from "./context/auth_context";

export function MyGroups() {  
    const { eligibleGroups, setEligibleGroups, isUuidValid, uuidTelegramUserData, telegramUserData, previousWalletEth, previousWalletBase, isConnected, isDisconnected, address } = useAuth();

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (eligibleGroups !== null) {
            return
        }

        setLoading(true)
        console.log('fetching users groups')

        fetch(`${window.origin}/api/get_users_groups`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                telegram_userid: telegramUserData ? telegramUserData.id : uuidTelegramUserData.telegram_userid
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data)
            console.log(data.groups)
            setEligibleGroups(data.groups)
        })
        .catch(error => {
            console.error('There was a problem with your fetch operation:', error);
            setEligibleGroups([])
        })
        .finally(() => {
            console.log('Fetch operation finished.');
            setLoading(false)
        });
    }, []);

    return (
        <>
            {loading && (<Spinner size="sm" className="mt-4" />)}

            {!loading && eligibleGroups && eligibleGroups.length === 0 && (
                <Card className="w-full sm:max-w-[400px] mx-auto">
                    <CardHeader className="flex gap-3">
                        <Image
                            alt="ozkey logo"
                            height={40}
                            radius="sm"
                            src="/pensive_emoji_face.png"
                            width={40}
                        />
                        <div className="flex flex-col">
                            <p className="text-md">Uh Oh!</p>
                            <p className="text-xs text-default-500">
                                You are not eligible for any groups at this time.                                            
                            </p>
                        </div>
                    </CardHeader>
                </Card>
            )}

            {!loading && eligibleGroups && eligibleGroups.length > 0 && (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 w-full">
                    {eligibleGroups.map((group, index) => (
                        <Card key={index} className="w-full sm:max-w-[400px]">
                            <CardHeader>
                                <div className="flex flex-col">
                                    <p className="text-md">{group.title}</p>
                                    {/* <p className="text-xs text-default-500">
                                        <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href={group.invite_link}>Join</Link>
                                    </p> */}
                                </div>
                            </CardHeader>
                            <CardBody className="flex flex-grow">
                            </CardBody>
                            <CardFooter>
                                <Button
                                    as={Link}
                                    isExternal 
                                    showAnchorIcon 
                                    anchorIcon={' ↗'} 
                                    href={group.invite_link}
                                    className="w-full"
                                    size="sm"
                                >
                                    Join
                                </Button>
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            )}
        </>
    );
}