import React, { useEffect } from "react";
import {NextUIProvider} from "@nextui-org/react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { AuthProvider } from "./components/context/auth_context";
import { AlertProvider } from "./components/context/alert_context";

import { Index } from "./pages/index";

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import { mainnet, base } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'd627e0295779b96cce6c9963e9e9f5a5'

// 2. Create wagmiConfig
const metadata = {
	name: 'Web3Modal',
	description: 'Web3Modal Example',
	url: 'https://web3modal.com', // origin must match your domain & subdomain
	icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, base]
const config = defaultWagmiConfig({
	chains, // required
	projectId, // required
	metadata, // required
	enableWalletConnect: true, // Optional - true by default
	enableInjected: true, // Optional - true by default
	enableEIP6963: true, // Optional - true by default
	enableCoinbase: true, // Optional - true by default
	// ...wagmiOptions // Optional - Override createConfig parameters
})

// 3. Create modal
createWeb3Modal({ wagmiConfig: config, projectId, chains })

export function Web3Modal({ children }) {
	return (
		<WagmiProvider config={config}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</WagmiProvider>
	)
}

function App() {
	const navigate = useNavigate();

	useEffect(() => {
        const root = document.documentElement;
        // Always apply these classes
        root.classList.add('dark', 'text-foreground', 'bg-background');

        // Cleanup function removes 'dark' but leaves others
        return () => {
            root.classList.remove('dark', 'text-foreground', 'bg-background');
        };
    }, []);

	return (
		<NextUIProvider navigate={navigate}>
			<Web3Modal>
				<AlertProvider>
					<AuthProvider>
						<Routes>
							<Route path='/:uuid' element={ <Index /> } />

							<Route path='*' element={<Index />} />
						</Routes>
					</AuthProvider>
				</AlertProvider>
			</Web3Modal>
		</NextUIProvider>
	);
}

export default App;
