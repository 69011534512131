import React, { useState, useEffect } from 'react';

import { Button } from "@nextui-org/react";


import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount, useSignMessage, useEnsName, useAccountEffect, useDisconnect } from 'wagmi'
import { getBalance } from '@wagmi/core'

import { createConfig, http } from '@wagmi/core'
import { mainnet } from '@wagmi/core/chains'

const config = createConfig({
    chains: [mainnet],
    transports: {
        [mainnet.id]: http('https://eth-mainnet.g.alchemy.com/v2/xrlqPOlzphLILotTkYlZDbkQlvecvSpG'),
    },
})

function truncate(address) {
    if (!address || address.length < 10) {
        return address; // return the original address if it's too short to truncate
    }
    return `${address.slice(0, 6)}...${address.slice(-4)}`; // Return the truncated address
}

export const WalletConnectButton = () => {
    const { address, isConnecting, isDisconnected, isConnected, isReconnected } = useAccount()
    const { open, close } = useWeb3Modal()
    const { disconnect } = useDisconnect()
    const { signMessage } = useSignMessage();

    const ensResult = useEnsName({
        address: address,
    })

    useEffect(() => {
        if (address) {
            console.log(ensResult)
        }
    }, [address, ensResult]);

    useAccountEffect({
        onConnect(data) {
            if (data.isReconnected) {
                console.log('reconnected')
            } else {
                console.log('first time connection', data.address)
            }
        }
    })

    return (
        <>
            <Button size="sm" onClick={() => open()}>
                {isConnected ? (ensResult ? truncate(ensResult.data) : truncate(address)) : 'Connect Wallet'}
            </Button>
        </>
    );
};