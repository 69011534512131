import React, { useState, useEffect, useRef } from "react";

import {Card, CardHeader, CardBody, CardFooter, Image, Divider, Link, Button, Tooltip, select} from "@nextui-org/react";

import { useAuth } from "./context/auth_context";
import { TelegramLoginWidgetTest } from "./telegram_login_widget_test";
import { MyGroups } from "./my_groups";

export function AuthCard() {  
    const { chains, handleSwitchChain, selectedChain, setSelectedChain, handleSetSelectedChainReset, chain, isUuidValid, uuidTelegramUserData, telegramUserData, previousWalletEth, previousWalletBase, open, handleOpen, disconnect, handleDisconnect, signMessage, isConnected, isDisconnected, address, truncateAddress, truncateTelegram, signedIn, handleSignMessageClick } = useAuth();

    // const onAuthSuccess = (authData) => {
    //     console.log("Authentication Successful:", authData);
    // };

    return (
        <>
            <Card className="w-full sm:max-w-[400px]">
                {signedIn && (
                    <CardHeader className="flex gap-3">
                        <Image
                            alt="ozkey logo"
                            height={40}
                            radius="sm"
                            src="/praise_hands_emoji.png"
                            // src="/key.png"
                            // src="/lock.png"
                            // src="/lock_key_1.png"
                            // src="/lock_key_2.png"
                            width={40}
                        />
                        <div className="flex flex-col">
                            <p className="text-md">Success!</p>
                            <p className="text-xs text-default-500">
                                Enjoy seamless authentication!
                            </p>
                        </div>
                    </CardHeader>
                )}

                {!signedIn && (
                    <>
                        {!isUuidValid && !telegramUserData && (
                            <CardHeader className="flex gap-3">
                                <Image
                                    alt="ozkey logo"
                                    height={40}
                                    radius="sm"
                                    src="/shushing_face_emoji.png"
                                    // src="/lock.png"
                                    // src="/lock_key_1.png"
                                    // src="/lock_key_2.png"
                                    width={40}
                                />
                                <div className="flex flex-col">
                                    <p className="text-md">Authentication</p>
                                    <p className="text-xs text-default-500 flex items-center space-x-1">
                                        <span>Press the <span className="italic">Getting Started</span> button above for instructions on how to authenticate.</span>
                                    </p>
                                </div>
                            </CardHeader>
                        )}

                        {(telegramUserData || isUuidValid) && (
                            <CardHeader className="flex gap-3">
                                <Image
                                    alt="ozkey logo"
                                    height={40}
                                    radius="sm"
                                    src="/key.png"
                                    // src="/lock.png"
                                    // src="/lock_key_1.png"
                                    // src="/lock_key_2.png"
                                    width={40}
                                />
                                <div className="flex flex-col">
                                    <p className="text-md">Account Details</p>
                                    <p className="text-xs text-default-500 flex items-center space-x-1">
                                        {/* Connected Telegram: {telegramUserData ? telegramUserData.id : null} */}
                                        <span>Connected Telegram:</span> 
                                        <span className="text-green-500">
                                            {isUuidValid ? 
                                                truncateTelegram(uuidTelegramUserData?.telegram_username || uuidTelegramUserData?.telegram_first_name || uuidTelegramUserData?.telegram_last_name || null) : 
                                                truncateTelegram(telegramUserData?.username || telegramUserData?.first_name || telegramUserData?.last_name || null)
                                            }
                                        </span>
                                    </p>
                                    <p className="text-xs text-default-500 flex items-center space-x-1">
                                        <span>Connected wallet:</span>
                                        <span className="text-green-500">
                                            {address ? truncateAddress(address) : null}
                                        </span>
                                    </p>
                                    <p className="text-xs text-default-500 flex items-center space-x-1">
                                        <span>Previous wallet (ETH):</span>
                                        <span className="text-green-500">
                                            {previousWalletEth ? truncateAddress(previousWalletEth) : null}
                                        </span>
                                    </p>
                                    <p className="text-xs text-default-500 flex items-center space-x-1">
                                        <span>Previous wallet (BASE):</span>
                                        <span className="text-green-500">
                                            {previousWalletBase ? truncateAddress(previousWalletBase) : null}
                                        </span>
                                    </p>
                                </div>
                            </CardHeader>
                        )}

                        <Divider/>

                        <CardBody>
                            <div className="space-y-4">
                                {!isUuidValid && !telegramUserData && (
                                    <>
                                        {/* <TelegramLoginWidgetTest onAuthSuccess={onAuthSuccess} /> */}
                                        <TelegramLoginWidgetTest />
                
                                        <div className="flex items-center justify-center">
                                            <div className="flex-grow">
                                                <Divider />
                                            </div>
                                            <span className="mx-4 text-sm whitespace-nowrap">OR</span>
                                            <div className="flex-grow">
                                                <Divider />
                                            </div>
                                        </div>
                
                                        <Button className="w-full" size="sm" as={Link} isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.me/OzKeyBot?start=start">DM @OzKeyBot</Button>
                                    </>
                                )}

                                {(telegramUserData || isUuidValid) && !isConnected && (
                                    <>
                                        {(previousWalletEth || previousWalletBase) && (
                                            <>
                                                <p className="font-semibold flex justify-center">Already Authorized!</p>
                                                <p className="mb-4 flex justify-center text-center text-default-500">Want to change wallets? Connect your new wallet, select the chain, and then click the sign message button.</p>
                                            </>
                                        )}

                                        <Button size="sm" className="w-full" onClick={handleOpen}>
                                            Connect Wallet
                                        </Button>
                                    </>
                                )}

                                {(telegramUserData || isUuidValid) && isConnected && !selectedChain && (
                                    <>
                                        <p className="font-semibold flex justify-center">Select a Chain</p>

                                        <div className="flex flex-wrap justify-center space-x-1">
                                            {chains.map((chain, index) => (
                                                <Button 
                                                    size="sm" 
                                                    key={chain.id} 
                                                    onClick={() => handleSwitchChain(chain.id)}
                                                    className={`mb-${index >= 0 ? '1' : '0'}`}
                                                >
                                                    {chain.name} ({chain.id})
                                                </Button>
                                            ))}
                                        </div>
                                    </>
                                )}

                                {(telegramUserData || isUuidValid) && isConnected && selectedChain && !signedIn && (
                                    <>
                                        <p className="font-semibold flex justify-center text-center">Sign the Message to Complete Authentication</p>

                                        <div className="flex flex-col justify-center">
                                            <Button size="sm" className="w-full" onClick={handleSignMessageClick}>
                                                Sign message
                                            </Button>

                                            <div className="flex flex-row justify-center space-x-4 mt-2">   
                                                <Link className="text-xs" href="#" onClick={handleDisconnect}>
                                                    Change wallet
                                                </Link>

                                                <Link className="text-xs" href="#" onClick={handleSetSelectedChainReset}>
                                                    Change chain
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </CardBody>
                        
                        {chain ? (
                            <>
                                <Divider/>
                
                                <CardFooter className="py-1">
                                    <div className="w-full text-xs text-default-500 flex justify-center">
                                        <p>
                                            Connected to: <span className="text-green-500">{`${chain.name} (${chain.id})`}</span>
                                        </p>
                                    </div>
                                </CardFooter>
                            </>
                        ) : null}
                    </>
                )}
            </Card>

            {/* {signedIn && (
                <MyGroups />
            )} */}
        </>
    );
}