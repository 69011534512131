import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import { Link, Button, Divider, Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image } from "@nextui-org/react";

import { useAuth } from "../components/context/auth_context";
import { useAlert } from "../components/context/alert_context";

import { WalletConnectButton } from "../components/wallet_connect";
import { Faq } from "../components/faq";
import { AuthCard } from "../components/auth_card";
import { CustomNavbar } from "../components/navbar";
import { GettingStarted } from "../components/getting_started";
import { MyGroups } from "../components/my_groups";

export function Index() {
    const { isUuidValid, uuidTelegramUserData, telegramUserData, previousWalletEth, previousWalletBase, isConnected, isDisconnected, address, signedIn } = useAuth();

    
    const { uuid } = useParams();
    const { setUuid } = useAuth();

    useEffect(() => {
        if (!uuid) {
            console.log('no uuid');
            return;
        } else {
            console.log('found uuid', uuid)
            setUuid(uuid);
        }
    }, [uuid]);

    return (
        <>            
            <CustomNavbar />

            <div className="p-6 mx-auto max-w-5xl">
                <div className="flex flex-col justify-center items-center text-center">
                    <p className="text-5xl">
                        {/* A better token-gating solution */}
                        A Better Token-Gating Solution
                    </p>
                    <p className="text-lg sm:text-xl text-default-500 mt-4">
                        {/* One-time connection, multiple uses, seamless authentication. */}
                        One-Time Connection, Multiple Uses, Seamless Authentication.
                    </p>
                    
                    <div className="mt-4">
                        <GettingStarted />
                    </div>
                </div>

                <div className="w-full mt-28"> 
                    <Tabs aria-label="Options" className="flex justify-center">
                        <Tab key="authentication_widget" title="Authentication Widget">
                            {/* <div className="flex justify-center"> */}
                            <div className="flex flex-col items-center justify-center space-y-4">
                                <AuthCard /> 
                                {signedIn && (
                                    <MyGroups />
                                )}
                            </div>
                        </Tab>
                        <Tab key="my_groups" title="My Groups">
                            {(telegramUserData || isUuidValid) ? (
                                <div className="flex justify-center">
                                    <MyGroups />
                                </div>
                            ) : (
                                <Card className="w-full sm:max-w-[400px] mx-auto">
                                    <CardHeader className="flex gap-3">
                                        <Image
                                            alt="ozkey logo"
                                            height={40}
                                            radius="sm"
                                            // src="/praise_hands_emoji.png"
                                            // src="/shushing_face_emoji.png"
                                            // src="/key.png"
                                            src="/lock.png"
                                            // src="/lock_key_1.png"
                                            // src="/lock_key_2.png"
                                            width={40}
                                        />
                                        <div className="flex flex-col">
                                            <p className="text-md">Unauthorized!</p>
                                            <p className="text-xs text-default-500">
                                                Login with Telegram in the Authentication Widget tab to see eligible groups.                                            
                                            </p>
                                        </div>
                                    </CardHeader>
                                </Card>  
                            )}
                        </Tab>
                    </Tabs>
                </div>

                <div className="mt-28 flex justify-center">
                    <Faq />
                </div>

                <Divider className="mt-28" />

                <div className="mt-4">
                    <p className="text-xs text-default-500">Built by <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://twitter.com/oz_dao">oz_dao</Link></p>
                </div>

                {/* <div className="mt-4">
                    <WalletConnectButton />
                </div>

                <div className="mt-4">
                    <Button size="sm" onClick={test}>test</Button>
                </div> */}
            </div>
        </>
    )
}