import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAlert } from './alert_context';
import { Button } from "@nextui-org/react";

import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount, useSignMessage, useEnsName, useAccountEffect, useDisconnect, useSwitchChain } from 'wagmi'
import { getBalance, switchChain } from '@wagmi/core'

import { createConfig, http } from '@wagmi/core'
import { mainnet, base } from '@wagmi/core/chains'

const config = createConfig({
    chains: [mainnet, base],
    transports: {
        [mainnet.id]: http('https://eth-mainnet.g.alchemy.com/v2/mUbvhjKY-o9VpqOYDLqPQlmXqRQCSvxK'),
        [base.id]: http('https://base-mainnet.g.alchemy.com/v2/XqS7kjd5z6HF9NOuW9WblYyTxT5NV-R9'),
    },
})

function truncateTelegram(name) {
    if (!name || name.length <= 15) {
        return name; // return the original name if it's too short to truncate
    }
    return `${name.slice(0, 6)}...${name.slice(-6)}`; // Return the truncated address
}

function truncateAddress(address) {
    if (!address || address.length < 10) {
        return address; // return the original address if it's too short to truncate
    }
    return `${address.slice(0, 6)}...${address.slice(-4)}`; // Return the truncated address
}

function truncateEns(ens) {
    if (!ens || ens.length < 10) {
        return ens; // return the original ens if it's too short to truncate
    }
    
    // Finding the position of '.eth'
    let ethIndex = ens.indexOf('.eth');

    // Extract the part before '.eth' and apply truncation
    let name = ens.slice(0, ethIndex);
    if (name.length <= 8) {
        return ens;
    }
    return `${name.slice(0, 4)}...${name.slice(-4)}.eth`;
}

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const { showAlert } = useAlert();

    const [uuid, setUuid] = useState(null)
    const [isUuidValid, setIsUuidValid] = useState(false);
    const [uuidTelegramUserData, setUuidTelegramUserData] = useState(null);

    const [telegramUserData, setTelegramUserData] = useState(null);

    // const [previousWallet, setPreviousWallet] = useState(null);
    const [previousWalletEth, setPreviousWalletEth] = useState(null);
    const [previousWalletBase, setPreviousWalletBase] = useState(null);

    const [signedIn, setSignedIn] = useState(false);

    const [selectedChain, setSelectedChain] = useState(null); // mainnet: 1, base: 8453, ...
    const [shouldDisconnect, setShouldDisconnect] = useState(true);

    const { chains } = useSwitchChain()

    const { address, isConnecting, isDisconnected, isConnected, isReconnected, chain } = useAccount()
    const { open, close } = useWeb3Modal()
    const { disconnect } = useDisconnect()
    const { signMessage } = useSignMessage();

    const [eligibleGroups, setEligibleGroups] = useState(null)

    // const ensResult = useEnsName({
    //     address: address,
    // })

    useEffect(() => {
        if (isConnected) {
            console.log(`${address} connected on chain ${chain.name} (${chain.id})`)
        }
    }, [isConnected]);

    useEffect(() => {
        if (isConnected) {
            console.log(`ue: ${address} connected on chain ${chain.name} (${chain.id}), selected: ${selectedChain}`)
        }
    }, [selectedChain, chain]);

    const handleSetSelectedChainReset = () => {
        setShouldDisconnect(false)
        setSelectedChain(null)
    };

    const handleSwitchChain = async (id) => {
        try {
            // if (id !== chain.id) {
                console.log(`Attempting to switch to chain ID: ${id}`);
                const result = await switchChain(config, { chainId: id });
                if (result.error) {
                    console.error(`Failed to switch chain:`, result.error);
                    showAlert(`Failed to switch to the chain: ${result.error.message}`, 'error', 'bottom');
                } else {
                    console.log(`Successfully switched to chain ID: ${id}`);
                    setSelectedChain(id);
                    showAlert(`Successfully switched to the new chain.`, 'success', 'bottom');
                }
            // } else {
            //     console.log('Error, selected chain is the current chain')
            //     showAlert('Error, selected chain is the current chain', 'error', 'bottom');
            // }
        } catch (error) {
            console.error(`Error while switching chains: ${error}`);
            showAlert(`An error occurred while switching chains: ${error.message}`, 'error', 'bottom');
        }
    };

    useEffect(() => {
        if (uuid) {
            console.log('fetching uuid', uuid)
            setTelegramUserData(null)

            fetch(`${window.origin}/api/check_uuid`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    uuid: uuid
                })
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log(data)
                if (data.ok) {
                    setIsUuidValid(!!data.ok);
                    console.log('valid uuid');
                    setUuidTelegramUserData(data.ok)
                    console.log('check uuid result', data.ok)
                } else {
                    console.log('invalid uuid:', data.error);
                    // triggerToast('Invalid/Expired code', 'alert-error');
                    showAlert('Invalid/Expired code', 'error', 'bottom')
                    navigate('/')
                }
            })
            .catch(error => {
                console.error('There was a problem with your fetch operation:', error);
            });
        }
    }, [uuid]);

    useEffect(() => {
        if (uuidTelegramUserData) {
            console.log('uuid telegram user data in auth context', uuidTelegramUserData)
            setTelegramUserData(null)

            fetch(`${window.origin}/api/my_wallet`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    telegram_userid: uuidTelegramUserData.telegram_userid
                })
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
    
                if (data['ok']) {
                    console.log('already connected a wallet')
                    // setPreviousWallet(data['ok'])
                    setPreviousWalletEth(data.wallet_address_eth);
                    setPreviousWalletBase(data.wallet_address_base);
                } else {
                    console.log('no wallet connected', data['error'])
                    // setPreviousWallet(null)
                    setPreviousWalletEth(null);
                    setPreviousWalletBase(null);
                }
            });
        }
    }, [uuidTelegramUserData]);

    useEffect(() => {
        if (telegramUserData) {
            console.log('telegram user data in auth context', telegramUserData)
            setUuidTelegramUserData(null)

            fetch(`${window.origin}/api/my_wallet`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    telegram_userid: telegramUserData.id
                })
            })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);

                if (data['ok']) {
                    console.log('already connected a wallet')
                    // setPreviousWallet(data['ok'])
                    setPreviousWalletEth(data.wallet_address_eth);
                    setPreviousWalletBase(data.wallet_address_base);
                } else {
                    console.log('no wallet connected', data['error'])
                    // setPreviousWallet(null)
                    setPreviousWalletEth(null);
                    setPreviousWalletBase(null);
                }
            });
        }
    }, [telegramUserData]);

    const handleOpen = () => {
        try {
            open();
        } catch (error) {
            console.error("Error opening the wallet modal:", error);
        }
    };

    const handleDisconnect = () => {
        try {
            disconnect();
        } catch (error) {
            console.error("Error disconnecting the wallet modal:", error);
        }
    };

    // disconnect if already connected to site
    // prevents it from going from tg widget/uuid > sign message and skipping connect wallet part
    useAccountEffect({
        onConnect(data) {
            console.log('isreconnected', data.isReconnected)
            if (data.isReconnected && shouldDisconnect) {
                disconnect()
            }

            setShouldDisconnect(false)
        }
    })

    const handleSignMessageClick = () => {
        let message = `Verifying my wallet\n\n${address}\n\non ozkey.app.\n\nChain: ${chain.name} (${chain.id})`
        if (isConnected) {
            signMessage({ 
                // message: `Verifying my wallet\n\n${address}\n\non ozkey.app.`,
                message: message
            }, {
                onSuccess(data) {
                    console.log('Successfully signed message', data);

                    fetch(`${window.origin}/api/sign_message`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            signer_address: address, 
                            signature_message: message,
                            signature: data,
                            origin: window.location.hostname,
                            telegram_user_data: isUuidValid ? uuidTelegramUserData: telegramUserData,
                            isUuid: isUuidValid,
                            chain_name: chain.name,
                            chain_id: chain.id
                        })
                    })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error("Network response was not ok");
                        }
                        return response.json();
                    })
                    .then((data) => {
                        if (data['ok']) {
                            // successful user/wallet writes
                            console.log(data['ok'])
                            setSignedIn(true)
                        } else {
                            // error when writing either user/wallet
                            let errorMessage = "";

                            console.log(data['error'])
                            if (data['error'].write_user_error) {
                                console.error('User Error:', data['error'].write_user_error)
                                errorMessage += `User Error: ${data['error'].write_user_error}`;
                            }
                            if (data['error'].write_wallet_error) {
                                console.error('Wallet Error:', data['error'].write_wallet_error);
                                errorMessage += `Wallet Error: ${data['error'].write_wallet_error}`;
                            }

                            if (errorMessage.length > 0) {
                                console.error('Combined Error:', errorMessage);
                                setSignedIn(false)
                                showAlert(errorMessage, 'error', 'bottom')
                            }
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch error: ", error);
                        setSignedIn(false)
                    });
                },
                onError(error) {
                    console.log('Error signing message', error);
                }
            });
        }
    };

    return (
        <AuthContext.Provider value={{ eligibleGroups, setEligibleGroups, chains, handleSwitchChain, selectedChain, setSelectedChain, handleSetSelectedChainReset, chain, setUuid, isUuidValid, uuidTelegramUserData, telegramUserData, setTelegramUserData, previousWalletEth, previousWalletBase, open, handleOpen, disconnect, handleDisconnect, signMessage, isConnected, isDisconnected, address, truncateAddress, truncateTelegram, signedIn, handleSignMessageClick }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
