import React, { useState, useEffect, useRef } from "react";
import { Link, Button, useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Tabs, Tab, Card, CardBody } from "@nextui-org/react";


export function GettingStarted() {   
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    return (
        <>
            <Button size="sm" variant="flat" onPress={onOpen}>Getting Started</Button>

            <Modal backdrop="blur" isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                Authentication Steps
                                <p className="text-xs text-default-500">
                                    Read the <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.wandbot.app/setting-up-wandbot">documentation</Link> for more detailed steps.
                                </p>
                            </ModalHeader>
                            <ModalBody>
                                <Tabs variant="underlined" aria-label="Options">
                                    <Tab key="website" title="Website">
                                        <div className="space-y-2">
                                            <Card>
                                                <CardBody className="text-default-500">
                                                    1️⃣ Connect your Telegram
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardBody className="text-default-500">
                                                    2️⃣ Connect your wallet
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardBody className="text-default-500">
                                                    3️⃣ Choose the chain you want to authenticate with
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardBody className="text-default-500">
                                                    4️⃣ Sign the message
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardBody className="text-default-500">
                                                    5️⃣ You are now verified with OzKey
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Tab>
                                    <Tab key="ozkeybot" title="OzKeyBot"> 
                                        <div className="space-y-2">
                                            <Card>
                                                <CardBody className="text-default-500">
                                                    <span>1️⃣ DM <Link className="" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.me/OzKeyBot?start=start">@OzKeyBot</Link> with the /start command</span>
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardBody className="text-default-500">
                                                    2️⃣ Follow the link OzKeyBot gives you
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardBody className="text-default-500">
                                                    3️⃣ Connect your wallet
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardBody className="text-default-500">
                                                    4️⃣ Choose the chain you want to authenticate with
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardBody className="text-default-500">
                                                    5️⃣ Sign the message
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardBody className="text-default-500">
                                                    6️⃣ You are now verified with OzKey
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </ModalBody>
                            <ModalFooter className="text-default-500">
                                <span className="text-xs">Telegram widget not working? DM <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.me/OzKeyBot?start=start">@OzKeyBot</Link> to get a unique UUID</span>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}